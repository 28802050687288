import * as React from "react";
import { IArticlePriceProps, IArticlePriceState, EPriceType } from "../../types/article-price";
import "./article-price.scss";

export default class ArticlePrice extends React.Component<IArticlePriceProps, IArticlePriceState> {
    constructor(props) {
        super(props);

        this.state = {
            currencySymbol: this.props.isDesktop
                ? this.props.priceSettings.currencySymbolWeb
                : this.props.priceSettings.currencySymbolMobile
        };
    }

    public render() {
        const isPriceTypeFrom = this.props.prices.minimalPrice.priceType === EPriceType.From;
        const isScalePrice = this.props.prices.minimalPrice.quantity > 1;

        return (
            <div className={"article-price"}>
                <div className={"price-info"}>
                    <div>
                        {this.props.prices.discountPrice && (
                            <>
                                <div className="strike-price net">
                                    <span className="value">
                                        {this.getPriceString(
                                            this.props.prices.discountPrice.originalPrice.netValue,
                                            isPriceTypeFrom
                                        )}
                                    </span>
                                </div>
                                <div className="strike-price gross">
                                    <span className="value">
                                        {this.getPriceString(
                                            this.props.prices.discountPrice.originalPrice
                                                .grossValue,
                                            isPriceTypeFrom
                                        )}
                                    </span>
                                </div>
                                &nbsp;
                            </>
                        )}
                        <div
                            className={
                                "price" +
                                `${this.props.prices.discountPrice ? " discounted" : ""}` +
                                " net"
                            }
                        >
                            <span className="value">
                                {this.getPriceString(
                                    this.props.prices.minimalPrice.price.netValue,
                                    isPriceTypeFrom
                                )}
                            </span>
                        </div>
                        <div
                            className={
                                "price" +
                                `${this.props.prices.discountPrice ? " discounted" : ""}` +
                                " gross"
                            }
                        >
                            <span className="value">
                                {this.getPriceString(
                                    this.props.prices.minimalPrice.price.grossValue,
                                    isPriceTypeFrom
                                )}
                            </span>
                        </div>
                    </div>
                    {this.props.prices.comparisonPrice && (
                        <>
                            <div className="comparison-price net">
                                <span className="price-prefix">
                                    {this.props.priceL10n.comparisonPrice}
                                    {this.props.priceL10n.colon}{" "}
                                </span>
                                <span className="value">{this.getComparisonPrice(true)}</span>
                            </div>
                            <div className="comparison-price gross">
                                <span className="price-prefix">
                                    {this.props.priceL10n.comparisonPrice}
                                    {this.props.priceL10n.colon}{" "}
                                </span>
                                <span className="value">{this.getComparisonPrice(false)}</span>
                            </div>
                        </>
                    )}
                </div>
                <div className={"vat-info"}>
                    <span className="price-mode net">({this.props.priceL10n.exVATAcronym})</span>
                    <span className="price-mode gross">({this.props.priceL10n.incVATAcronym})</span>
                    {isScalePrice && (
                        <span className="quantity">&nbsp;{this.getQuantityString()}</span>
                    )}
                </div>
            </div>
        );
    }

    private getPriceString(priceValue: string, isPriceTypeFrom: boolean): string {
        return isPriceTypeFrom
            ? `${this.props.priceL10n.priceFromLocale} ${priceValue} ${this.state.currencySymbol}`
            : `${priceValue} ${this.state.currencySymbol}`;
    }

    private getComparisonPrice(isNet: boolean): string {
        const priceValue = isNet
            ? this.props.prices.comparisonPrice.price.netValue
            : this.props.prices.comparisonPrice.price.grossValue;
        return `${priceValue} ${this.state.currencySymbol}/${this.props.prices.comparisonPrice.unit}`;
    }

    private getQuantityString(): string {
        return `${this.props.priceL10n.priceFromLocale} ${this.props.prices.minimalPrice.quantity} ${this.props.prices.quantityUnitPlural}`;
    }
}
