export interface IMinimalPriceContainer {
    minimalPrice: IMinimalPrice;
    comparisonPrice: IComparisonPrice;
    discountPrice: IDiscountPrice;
    quantityUnitPlural: string;
}

export interface IMinimalPrice {
    price: IPrice;
    quantity: number;
    priceType: EPriceType;
}

export enum EPriceType {
    Only = "only",
    From = "from"
}

export interface IComparisonPrice {
    price: IPrice;
    unit: string;
}

export interface IDiscountPrice {
    originalPrice: IPrice;
}

export interface IPrice {
    netValue: string;
    grossValue: string;
}

export interface IPriceMandantSettings {
    currencySymbolWeb: string;
    currencySymbolMobile: string;
}

export interface IArticlePriceProps {
    prices: IMinimalPriceContainer;
    priceSettings: IPriceMandantSettings;
    isDesktop: boolean;
    priceL10n: IPriceL10n;
}

export interface IArticlePriceState {
    currencySymbol: string;
}

export interface IPriceL10n {
    colon: string;
    comparisonPrice: string;
    exVATAcronym: string;
    incVATAcronym: string;
    priceFromLocale: string;
}
