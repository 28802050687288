import Search2021 from "../../Assets/svg/Search_2021_icon";

import * as debounceSsw from "lodash/debounce";
import * as React from "react";
import * as LocaHelper from "../../util/localizationHelper";
import { ISearchBoxGridProps, ISearchBoxGridStates } from "../../types/searchboxgrid";

import Modal from "../modal/modal";
import Suggestions from "../suggestions/suggestions";
import SuggestionsWithImagesAndPrices from "../suggestions/suggestionsWithImagesAndPrices";

import "./searchboxgrid.scss";
import Search from "../../util/search";

export default class SearchBoxGrid extends Search<ISearchBoxGridProps, ISearchBoxGridStates> {
    private abortController: AbortController | undefined;
    private suggestionWrapperRef = React.createRef<HTMLDivElement>();
    private scopeSsw: Element;

    constructor(props: ISearchBoxGridProps) {
        super(props);

        this.state = {
            focus: false,
            searchTerm: "",
            showModal: false,
            suggestions: [],
            articles: [],
            contents: [],
            featurePromotion: "",
            topSearchQueries: [],
            useNewSuggestions: false,
            customerType: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleKeyPressed = this.handleKeyPressed.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.activateFocusHelper = this.activateFocusHelper.bind(this);
        this.activateFocus = this.activateFocus.bind(this);
        this.deactivateFocus = this.deactivateFocus.bind(this);
        this.updateSuggestionsVisibleStatus = this.updateSuggestionsVisibleStatus.bind(this);
    }

    public componentDidMount(): void {
        document.addEventListener("mousedown", this.handleClick, false);
        this.scopeSsw = this.searchBoxRef.current.closest(".scope-ssw");
        this.setState({ customerType: this.getCostumerType() });
    }

    public componentDidUpdate(): void {
        this.bindKeyboardNavigation();
        this.updateSuggestionsVisibleStatus();
    }

    public componentWillUnmount(): void {
        document.removeEventListener("mousedown", this.handleClick, false);
    }

    public render() {
        const dontShowSuggestions = !this.areSuggestionsInResponse() || !this.state.searchTerm;
        const priceL10n = LocaHelper.decodePriceL10n(this.props.l10n.prices);
        const productFinderL10n = LocaHelper.decodeProductFinderL10n(this.props.l10n.finders);
        const variantsL10n = LocaHelper.decodeVariantsL10n(this.props.l10n.variants);

        return (
            <div
                className={
                    "searchbox-grid" +
                    ` ${this.state.focus ? "focus" : ""}` +
                    ` ${dontShowSuggestions ? "" : "show-suggestions"}` +
                    ` ${this.props.tenant}` +
                    ` ${this.state.useNewSuggestions ? "" : "legacy"}` +
                    ` ${this.props.isEsApp ? "esApp" : ""}`
                }
                ref={this.searchBoxRef}
            >
                <form
                    className={"search-grid"}
                    onSubmit={this.handleSubmit}
                    onClick={this.activateFocusHelper}
                >
                    <button type="submit" className="search-grid-button">
                        <Search2021 />
                    </button>
                    <span className="transition-wrapper">
                        <span
                            className="input-sizer"
                            data-value={
                                this.state.searchTerm ||
                                (this.state.focus
                                    ? ""
                                    : this.decodeHTML(this.props.l10n.placeholder))
                            }
                        >
                            <input
                                ref={this.inputRef}
                                className="search-grid-input"
                                name="query"
                                value={this.state.searchTerm}
                                onChange={this.handleChange}
                                onFocus={this.activateFocus}
                                placeholder={
                                    this.state.focus
                                        ? ""
                                        : this.decodeHTML(this.props.l10n.placeholder)
                                }
                                maxLength={50}
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="none"
                                spellCheck="false"
                                size={1}
                            />
                        </span>
                    </span>
                </form>
                <hr />
                {this.state.useNewSuggestions ? (
                    <div id={"new-suggestions-grid"} className={"new-suggestions"}>
                        <SuggestionsWithImagesAndPrices
                            trackingEndpoint={this.props.trackingEndpoint}
                            portal={this.props.portal}
                            culture={this.props.culture}
                            ref={this.suggestionsWithImagesAndPricesRef}
                            isWide={this.props.view === "wide"}
                            view={"grid"}
                            searchTerm={this.state.searchTerm}
                            articles={this.state.articles}
                            contents={this.state.contents}
                            featurePromotion={this.state.featurePromotion}
                            topSearchQueries={this.state.topSearchQueries}
                            priceMandantSettings={this.state.priceMandantSettings}
                            customerType={this.state.customerType}
                            priceL10n={priceL10n}
                            seeAllResults={this.decodeHTML(this.props.l10n.seeAllResults)}
                            articlesSuggestHeadline={this.decodeHTML(
                                this.props.l10n.articlesSuggestHeadline
                            )}
                            contentSuggestHeadline={this.decodeHTML(
                                this.props.l10n.contentSuggestHeadline
                            )}
                            topSearchQueriesHeadline={this.decodeHTML(
                                this.props.l10n.topSearchQueriesHeadline
                            )}
                            productFinderL10n={productFinderL10n}
                            variantsL10n={variantsL10n}
                            searchTarget={this.props.l10n.searchTarget}
                        />
                    </div>
                ) : (
                    <div
                        className={
                            this.hasMultipleSuggestionListsForLegacy()
                                ? "suggestions multiple-lists"
                                : "suggestions"
                        }
                        ref={this.suggestionWrapperRef}
                    >
                        <Suggestions
                            trackingEndpoint={this.props.trackingEndpoint}
                            portal={this.props.portal}
                            culture={this.props.culture}
                            ref={this.suggestionRef}
                            suggestions={this.state.suggestions}
                            searchTerm={this.state.searchTerm}
                            showMultipleSuggestionLists={this.hasMultipleSuggestionListsForLegacy()}
                            articlesSuggestHeadline={this.decodeHTML(
                                this.props.l10n.articlesSuggestHeadline
                            )}
                            contentSuggestHeadline={this.decodeHTML(
                                this.props.l10n.contentSuggestHeadline
                            )}
                            featurePromotion={this.state.featurePromotion}
                            topSearchQueries={this.state.topSearchQueries}
                            topSearchQueriesHeadline={this.decodeHTML(
                                this.props.l10n.topSearchQueriesHeadline
                            )}
                            productFinderL10n={productFinderL10n}
                            searchTarget={this.props.l10n.searchTarget}
                        />
                    </div>
                )}

                {this.isDesktop && (
                    <Modal
                        open={this.state.showModal}
                        tenant={this.props.tenant}
                        closeModal={this.hideModal}
                        l10n={this.props.l10n}
                        featureToggles={this.props.featureToggles}
                    />
                )}
            </div>
        );
    }

    private getCostumerType(): string {
        const customerTypeValue = document.cookie
            .split("; ")
            .find((row) => row.startsWith("CustomerType="))
            ?.split("=")[1];
        // use Private as default value
        return customerTypeValue ? customerTypeValue : "Private";
    }

    private handleSubmit(event): void {
        event.preventDefault();
        if (this.state.searchTerm === "") {
            this.setState({ showModal: true, focus: false });
            this.inputRef.current.blur();
        } else
            window.location.href = `${this.props.l10n.searchTarget}?query=${encodeURIComponent(
                this.state.searchTerm
            )}`;
    }

    private handleChange(event): void {
        if (!event || !event.target) {
            this.resetSuggestionState();
            return;
        }

        const validSearchTerm =
            event.target.value.length > 50 ? event.target.value.slice(0, 50) : event.target.value;

        this.setState({ searchTerm: validSearchTerm });

        event.target.value ? this.debouncedFetchSuggestions(event) : this.resetSuggestionState();
    }

    private debouncedFetchSuggestions(event): void {
        event.persist();

        if (!this.debouncedFetchSuggestionsFunc)
            this.debouncedFetchSuggestionsFunc = debounceSsw(() => {
                this.fetchSuggestion(event);
            }, 250);

        this.debouncedFetchSuggestionsFunc();
    }

    private fetchSuggestion(event): void {
        if (!event || !event.target || !event.target.value || !event.target.value.trim().length) {
            this.resetSuggestionState();
            return;
        }

        const searchTerm: string = event.target.value;
        if (searchTerm.length > 50) return;

        const topSearchQueriesLimit: number = this.isDesktop ? 10 : 5;

        const headers = this.pageViewLogDataId.get()
            ? { "X-PageView-ID": this.pageViewLogDataId.get() }
            : {};

        if (this.abortController) {
            this.abortController.abort();
        }

        this.abortController = new AbortController();
        const signal = this.abortController.signal;

        fetch(
            `${this.getSuggestionUrl()}?searchTerm=${encodeURIComponent(
                searchTerm
            )}&topSearchQueriesLimit=${topSearchQueriesLimit}`,
            { headers, signal }
        )
            .then((response) => response.json())
            .then(
                (response) => {
                    if (response) {
                        // check for legacy or new suggestion type
                        if (response.clientSuggestionsList) {
                            // legacy suggestion type
                            response.clientSuggestionsList.length ||
                            response.topSearchQueries.length
                                ? this.setState({
                                      useNewSuggestions: false,
                                      suggestions: response.clientSuggestionsList,
                                      featurePromotion: response.featurePromotion,
                                      topSearchQueries: response.topSearchQueries
                                  })
                                : this.resetSuggestionState();
                        } else {
                            // new suggestion type
                            response.articles.length ||
                            response.contents.length ||
                            response.topSearchQueries.length
                                ? this.setState({
                                      useNewSuggestions: true,
                                      articles: response.articles,
                                      contents: response.contents,
                                      featurePromotion: response.featurePromotion,
                                      topSearchQueries: response.topSearchQueries,
                                      priceMandantSettings: response.priceMandantSettings
                                  })
                                : this.resetSuggestionState();
                        }
                    } else this.resetSuggestionState();
                },
                () => {
                    this.resetSuggestionState();
                }
            )
            .then(() => (this.abortController = undefined));
    }

    private areSuggestionsInResponse(): boolean {
        if (this.state.useNewSuggestions) {
            return (
                this.state.articles.length > 0 ||
                this.state.contents.length > 0 ||
                this.state.topSearchQueries.length > 0
            );
        } else {
            return (
                this.state.suggestions.some((list) => list.clientSuggestion.length > 0) ||
                this.state.topSearchQueries.length > 0
            );
        }
    }

    private hasMultipleSuggestionListsForLegacy(): boolean {
        if (this.state.useNewSuggestions) return false;
        return (
            this.state.suggestions.some((list) => list.clientSuggestion.length > 0) &&
            this.state.topSearchQueries.length > 0
        );
    }

    private resetSuggestionState(): void {
        this.setState((state: ISearchBoxGridStates) => ({
            ...state,
            suggestions: [],
            featurePromotion: "",
            topSearchQueries: [],
            articles: [],
            contents: [],
            priceMandantSettings: {}
        }));
    }

    private getSuggestionUrl(): string {
        const culturePrefix: string = "/" + this.props.culture.split("-")[0];
        return culturePrefix + "/api/shopsearch-web/suggestion/";
    }

    private decodeHTML(value: string): string {
        return value.replace(/&#(\d+);/g, (_match, dec) => {
            return String.fromCharCode(dec);
        });
    }

    private handleClick(event): void {
        if (!this.searchBoxRef.current.contains(event.target)) this.deactivateFocus();
    }

    private activateFocusHelper(): void {
        this.activateFocus();
        this.inputRef.current.focus();
    }

    private activateFocus(): void {
        if (this.state.focus) return;

        this.setState({ focus: true });

        if (this.isDesktop) this.scrollSearchboxToTopDesktop();
    }

    private deactivateFocus(): void {
        if (!this.state.focus) return;

        this.setState({ focus: false });
        this.inputRef.current.blur();
    }

    private bindKeyboardNavigation(): void {
        if (!this.isDesktop) return;

        this.state.focus
            ? document.addEventListener("keydown", this.handleKeyPressed)
            : document.removeEventListener("keydown", this.handleKeyPressed);
    }

    private handleKeyPressed(event: KeyboardEvent): void {
        if (this.state.useNewSuggestions) {
            switch (event.key) {
                case "Down":
                case "ArrowDown":
                    event.preventDefault();
                    if (this.suggestionsWithImagesAndPricesRef.current != null)
                        this.suggestionsWithImagesAndPricesRef.current.selectNextSuggestionEntry(
                            false
                        );
                    break;
                case "Up":
                case "ArrowUp":
                    event.preventDefault();
                    if (this.suggestionsWithImagesAndPricesRef.current != null)
                        this.suggestionsWithImagesAndPricesRef.current.selectNextSuggestionEntry(
                            true
                        );
                    break;
                case "Enter":
                    if (this.suggestionsWithImagesAndPricesRef.current != null) {
                        event.preventDefault();
                        const executedRedirect =
                            this.suggestionsWithImagesAndPricesRef.current.triggerSuggestionRedirect();
                        if (!executedRedirect) this.handleSubmit(event);
                    } else this.handleSubmit(event);
                    break;
                case "Esc":
                case "Escape":
                    if (this.suggestionsWithImagesAndPricesRef.current != null)
                        this.deactivateFocus();
                    break;
                default:
                    return;
            }
        } else {
            switch (event.key) {
                case "Down":
                case "ArrowDown":
                    event.preventDefault();
                    if (this.suggestionRef.current != null)
                        this.suggestionRef.current.selectNextSuggestionEntry(false);
                    break;

                case "Up":
                case "ArrowUp":
                    event.preventDefault();
                    if (this.suggestionRef.current != null)
                        this.suggestionRef.current.selectNextSuggestionEntry(true);
                    break;

                case "Enter":
                    if (this.suggestionRef.current != null) {
                        event.preventDefault();
                        const executedRedirect =
                            this.suggestionRef.current.triggerSuggestionRedirect();
                        if (!executedRedirect) this.handleSubmit(event);
                    } else this.handleSubmit(event);
                    break;

                case "Esc":
                case "Escape":
                    if (this.suggestionRef.current != null) this.deactivateFocus();
                    break;

                default:
                    return;
            }
        }
    }

    private scrollSearchboxToTopDesktop(): void {
        // on small / little height devices e.g. tablets in landscape
        // scrolling the searchbox up to have more space for suggestions
        const offsetTop: number = this.searchBoxRef.current.offsetTop;
        const heightBelowIncludingSearch: number = window.innerHeight - offsetTop;

        if (heightBelowIncludingSearch > 590) return;

        window.scroll({
            behavior: "smooth",
            top: offsetTop - 55
        });
    }

    private updateSuggestionsVisibleStatus(): void {
        const suggestionsVisibleStatus: boolean =
            this.state.focus && this.areSuggestionsInResponse() && !!this.state.searchTerm;

        suggestionsVisibleStatus
            ? this.scopeSsw.classList.add("suggestions-visible")
            : this.scopeSsw.classList.remove("suggestions-visible");
    }

    private hideModal = () => {
        this.setState({ showModal: false });
    };
}
